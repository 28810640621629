import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService, BcSnackBarService } from 'bcSharedComponents';
import { AutocompleteDto, KundenNeuanlageService } from 'bcWwsWebApi';
import { NewsLetterComponent } from 'projects/bc-account/src/app/newsletter/newsletter-component/newsletter-component';

@Component({
  selector: 'app-user-profile-dialog',
  standalone: true,
  imports: [
            
            MatButtonModule,
            MatDialogModule,
            MatFormFieldModule,
            MatInputModule,
            ReactiveFormsModule],

  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.sass']
})
export class UserProfileDialogComponent implements OnInit {


  userForm = this.fb.group({
    email: ['',],
    firstName: [''],
    lastName: [''],
    phone: ['']
  });
  user: AutocompleteDto = {} as AutocompleteDto;

  constructor(
    public dialog: MatDialog,
    private authService : AuthService, 
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserProfileDialogComponent>,
    private kundenNeuanlageService: KundenNeuanlageService,
    private snackBar: BcSnackBarService
  ) {}

  ngOnInit(): void {
    this.kundenNeuanlageService.apiKundenNeuanlageGetUserBaseDataGet().subscribe({
      next: (result: AutocompleteDto) => {
        this.user = result;
        this.userForm.patchValue({
          email: result.email,
          firstName: result.vorname,
          lastName: result.nachname,
          phone: result.telefonnummer
        });
       },
      error: (err) => {
        console.log(err);
        this.snackBar.show('Fehler beim Laden der Benutzerdaten');
        this.onClose();
      }
    });
  }

    openNewsletterSubscribeDialog(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      const dialogRefer = this.dialog.open(
        NewsLetterComponent,
        dialogConfig
      );
    }
  onVerwalten() {
    this.authService.navigateToVerwalten();
    }
  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {

    this.user.vorname = this.userForm.get('firstName')!.value!;
    this.user.nachname = this.userForm.get('lastName')!.value!;
    this.user.telefonnummer = this.userForm.get('phone')!.value!;

    this.kundenNeuanlageService.apiKundenNeuanlageSetUserBaseDataPost(this.user).subscribe({
      next: (result: AutocompleteDto) => {
        this.dialogRef.close();
      },
      error: (err) => {
        console.log(err);
        this.snackBar.show('Fehler beim Speichern der Benutzerdaten!\r\n Bitte wenden Sie sich an Ihren Administrator.');
        this.onClose();
      }
    });
}
}