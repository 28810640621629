import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import { ApiValidationService} from 'bcWwsWebApi';
import { Observable, map, pipe } from 'rxjs';

export function phoneNumberValidator(service: ApiValidationService): AsyncValidatorFn {
    return (control:AbstractControl) :  Observable<ValidationErrors | null> => {

        return service.apiApiValidationValidatePhoneNumberGet(control.value)
                .pipe(
                    map((value) => !value.isValid ? {isPhoneNumberValid : false, message: value.errorMessage } : null)
                );    
    }
}