import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { AuthGuardWithForcedLogin } from '../bc-auth/auth-guard-with-forced-login.service';

const routes: Routes = [
  {
    path: 'noright',
    component: NoRightsComponent,
    canActivate: [AuthGuardWithForcedLogin],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonRoutingModule { }
