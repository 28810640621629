import { Component } from '@angular/core';

@Component({
  selector: 'app-no-rights',
  templateUrl: './no-rights.component.html',
  styleUrls: ['./no-rights.component.sass']
})
export class NoRightsComponent {
  //this.imagePath = 'assets/Logo-Bluechip-Blau.png';
}
