<h2 mat-dialog-title>Benutzerdetails</h2>
<mat-dialog-content>
  <div class="dialog-content">
    <div class="form-section">
      <form [formGroup]="userForm">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput [readonly]="true" formControlName="email" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Vorname</mat-label>
          <input matInput formControlName="firstName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Nachname</mat-label>
          <input matInput formControlName="lastName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Telefon</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>
      </form>
    </div>
    <div class="info-section">
      <p>
        Verwalten Sie Ihre Kontosicherheit
        Über den Button Sicherheit gelangen Sie zur Verwaltung Ihrer Anmeldemöglichkeiten. Dort können Sie die Aktivität Ihrer Geräte einsehen, Anwendungen überprüfen, die auf Ihr Konto zugreifen, und Ihre Sicherheitseinstellungen anpassen.
      </p>
      <button mat-raised-button color="primary" (click)="onVerwalten()">Sicherheit</button>
      <p>
        Newsletter-Abonnement verwalten
      </p>
      <button mat-raised-button color="primary" (click)="openNewsletterSubscribeDialog()">Verwalten</button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()">Schließen</button>
  <button mat-raised-button color="accent" (click)="onSave()">Speichern</button>
</mat-dialog-actions>
