import { Component } from '@angular/core';

@Component({
  selector: 'lib-bcSharedComponents',
  template: `
    <p>
      bc-shared-components works!
    </p>
  `,
  styles: [
  ]
})
export class BcSharedComponentsComponent {

}
