import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseURLsDto, StatisticsService } from 'bcWwsWebApi';
import { takeUntil } from 'rxjs';
import { Destroyer } from '../../bc-auth/destroyer';

@Component({
  selector: 'bc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent extends Destroyer implements OnInit {

 baseUrls!:BaseURLsDto;

  constructor(private router: Router, private statisticService:StatisticsService) {
    super();
   }

  ngOnInit(): void {
    
    this.statisticService.apiStatisticsGetBaseGet()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next:(res:BaseURLsDto)=>
      {
        this.baseUrls = res;
      }
    })

    
  }
  navServiceVereinbarungen()
  {
    var url = this.baseUrls.service+"/servicevereinbarungen";

    window.open(url, "_blank");
  
  }
}

