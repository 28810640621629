import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
  } from "@angular/core";
  import {
    FormControl,
    FormGroupDirective,
    NgControl,
    NgForm
  } from "@angular/forms";
  import { FocusMonitor } from "@angular/cdk/a11y";
  import { Subject, Subscription, takeUntil } from "rxjs";
  import { MatInput } from "@angular/material/input";
  import { AbstractMatFormField } from "./abstract-mat-form-field";
import { MatFormFieldControl } from "@angular/material/form-field";
import { ErrorStateMatcher } from "@angular/material/core";
import { ApiValidationService } from "bcWwsWebApi";
  
  @Component({
    selector: "bc-phone-input",
    template: `
        <input
            matInput
            (focusout)="onFocusOutEvent($event)"
            type="text"
            [formControl]="control"
            [placeholder]="_placeholder"
        />
    `,
    providers: [
      {
        provide: MatFormFieldControl,
        useExisting: BcPhoneInputComponent
      }
    ]
  })
  export class BcPhoneInputComponent extends AbstractMatFormField<string>
    implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
        
    public control = new FormControl();
  
    @ViewChild(MatInput, { static: false })
    private input! : MatInput;
  
    private subscription: Subscription = {} as Subscription;
  
    constructor(
      @Optional() @Self() ngControl: NgControl,
      @Optional() _parentForm: NgForm,
      @Optional() _parentFormGroup: FormGroupDirective,
      _defaultErrorStateMatcher: ErrorStateMatcher,
      _focusMonitor: FocusMonitor,
      _elementRef: ElementRef,
      private validationService: ApiValidationService,
    ) {
      super(
        "bc-phone-input",
        ngControl,
        _parentForm,
        _parentFormGroup,
        _defaultErrorStateMatcher,
        _focusMonitor,
        _elementRef
      );
    }
  
    public ngOnInit(): void {
      this.subscription = this.control.valueChanges.subscribe(value => {
        super.value = value;
      });
    }
  
    public override ngOnDestroy(): void {

        this.destroy$.next(true);
        this.destroy$.unsubscribe();

      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      super.ngOnDestroy();
    }
  
    onFocusOutEvent(event: any) {
        this.validationService.apiApiValidationToInternationalPhoneNumberGet(event.target.value).pipe(takeUntil(this.destroy$))
        .subscribe({next: (res) => 
          {
            this.value = res;
          }
        });
    }
    

    public override set value(value: string) {
      this.control.setValue(value);
      super.value = value;
    }
  
    public focus(): void {
      this.input.focus();
    }
  }