
<div class="grid-container">
    <div class="grid-wrapper">
      <div class="image-column">
        <img src="assets/denied.png" alt="Zugang Verweigert">
      </div>
      <div class="content-column">
        <div class="content">
          <h1>Zugang verweigert</h1>
          <h4>Sie haben keine Berechtigung, diesen Inhalt zu sehen.</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="button-container">
    <button mat-raised-button routerLink="/">Zurück zum Start</button>
  </div>
  