import {Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, Subject, combineLatest, first, forkJoin, from, iif, map, merge, mergeMap, pipe, takeUntil} from 'rxjs';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatSidenav} from "@angular/material/sidenav";
import { FormControl } from '@angular/forms';
import { IApplicationItem, IMenuItem, MenuItemVisibility } from './IMenuItem';

import {AuthService, Destroyer} from 'bcSharedComponents';
import {UserInfo} from "angular-oauth2-oidc";
import { TenantSelectorDialogComponent } from './tenant-selector-dialog/tenant-selector-dialog.component';
import { MessageService, RightsService } from 'bcWwsWebApi';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';

@Component({
  selector: 'bc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent extends Destroyer implements OnInit {
  @Input() menuItems: IMenuItem[] = [];
  @Input() hasToBeLoggedIn: boolean = true;
  @Input() applicationItems$: Observable<IApplicationItem[]> = from([]);

  applicationItems : IApplicationItem[] = [];

  @ViewChild('drawer') drawer!: MatSidenav;
  
  readonly darkClassName : string = 'darkMode';
  toggleControl = new FormControl(false);
  username: string = "";
  tenant: string = localStorage.getItem('x-tenant') ? `(${localStorage.getItem('x-tenant')})` : "";
  darkMode: boolean = false;
  imagePath: string = 'assets/Logo-Bluechip-Blau.png';
  hasMultipleTennants$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService : AuthService, 
    private router: Router, 
    public dialog: MatDialog, 
    private renderer: Renderer2,
    private rightService : RightsService,
    private messageService: MessageService
    ) {
      super();
      const isDarkMode = localStorage.getItem(this.darkClassName);
      this.darkMode = isDarkMode !== null ? Boolean(JSON.parse(isDarkMode)) : false;
      localStorage.setItem(this.darkClassName, String(this.darkMode));
  }

  public darkModeClick(){
    this.darkMode = !this.darkMode;
    localStorage.setItem(this.darkClassName, String(this.darkMode));
    this.setDarkMode();
  }


//UNBEDINGT WIEDER ENTFERNEN

public checkRoute(subitem : IMenuItem) : Observable<boolean>{
    return from ([subitem.routerLink == undefined ?  true : false])

  }
//------------



  public checkRoles(menuItem : IMenuItem) : Observable<boolean>{
    return combineLatest([this.authService.hasRoles(menuItem.requiredRoles, menuItem.rolesPredictionFunc), this.CheckVisibility(menuItem)])
            .pipe(map(([v1, v2]) => v1 && v2));
  }

  public hasSubItems(menuItem : IMenuItem) : Observable<boolean>{
    return from([(menuItem.subItems !== undefined ? menuItem.subItems?.length > 0 : false)]);
  }

  
  /**
   * Checks the visibility of a menu item based on its visibility property.
   * @param menuItem - The menu item to check visibility for.
   * @returns An Observable that emits a boolean value indicating the visibility of the menu item.
   */
  private CheckVisibility(menuItem : IMenuItem) : Observable<boolean> {
    switch (menuItem.visibility) {
      case MenuItemVisibility.Always:
        return from([true]);
      case MenuItemVisibility.BeforeLogin:
        return this.authService.isAuthenticated$.pipe(map(x => !x));
      case MenuItemVisibility.AfterLogin:
        return this.authService.isAuthenticated$.pipe(map(x => x));
      default:
        return from([true]);
    }
  }

  setDarkMode(){
    if(this.darkMode){
      this.renderer.addClass(document.body, this.darkClassName);
      this.imagePath = 'assets/Logo-Bluechip-Weiss.png';
    }
    else
    {
      this.renderer.removeClass(document.body, this.darkClassName);
      this.imagePath = 'assets/Logo-Bluechip-Blau.png';
    }
  }

  public isLoggedIn = this.authService.hasValidToken();
  public isTenantLocked$ = this.authService.isTenantLocked$;
  public tenantLockedMessage$ = this.messageService.apiMessageGetLockedMessageGet();

  public ngOnInit() {
    this.setDarkMode();
    
    if(this.isLoggedIn){
      const claims = this.authService.identityClaims as UserInfo;
      if(!!claims){
        this.username = claims['preferred_username'];
        localStorage.setItem('email', claims['email']);

        this.rightService.apiRightsGetTenantsGet().pipe(takeUntil(this.destroy$))
                          .subscribe(value => value.length > 1 ? this.hasMultipleTennants$.next(true) : this.hasMultipleTennants$.next(false));

        if(!localStorage.getItem('x-tenant')){
          this.setTenantInfo();
        }
      }
    }
  }

  private setTenantInfo(){
    this.rightService.apiRightsGetTenantsGet()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (tenants) => {
        const i: number = 0;
        if(tenants.length > 1)
        {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = tenants;
  
          const dialogRef = this.dialog.open(
            TenantSelectorDialogComponent,
            dialogConfig
          );
          
          dialogRef.afterClosed().pipe(first()).subscribe( 
              data =>{ 
                this.rightService.apiRightsSetCurrentTenantGet(data.result)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                  {complete: () => {
                    localStorage.setItem('x-tenant', data.result); 
                    window.location.reload();
                  }}
                )
              }
            );
          }
          else if(tenants.length ==1)
          {
            localStorage.setItem('x-tenant', tenants[0].tennant!.toString());
            //window.location.reload();
          }
      }
      })
  }

  public setTenant(){
        this.setTenantInfo();
  }

  public login() {
    this.authService.login();
  }

  public logout() {
    this.authService.revokeTokenAndLogout();
  }
  
  // SSO seite wird nicht mehr benötigt
  // public navigateToVerwalten(){
  //   this.authService.navigateToVerwalten();
  // }
  openUserProfileDialog(): void {
    const dialogRef = this.dialog.open(UserProfileDialogComponent);
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
      }
    });
  }

  closeMenu() {
    this.drawer.close();
  }

  navigateTo(url :string){
    window.open(url);
  }

  updateApplications(){
    this.applicationItems$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next:(value) =>{ this.applicationItems = value; }
    });
  }

}

