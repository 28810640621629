import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonRoutingModule } from './common-routing.module';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    NoRightsComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule
  ]
})
export class BcCommonModule { }
