import { NgModule } from '@angular/core';
import { BcSharedComponentsComponent } from './bc-shared-components.component';
import { BcFooterModule } from './bc-footer/bc-footer.module';
import { BcHeaderModule } from './bc-header/bc-header.module';
import { BcAuthCoreModule } from './bc-auth/bc-auth-core.module';
import { BcSnackBarService } from './bc-snackbar/bc-snackbar-service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BcSnackbarModule } from './bc-snackbar/bc-snackbar-module';


@NgModule({
  declarations: [
    BcSharedComponentsComponent,
  ],
  imports: [
    MatSnackBarModule
  ],
  exports: [
    BcSharedComponentsComponent,
    BcFooterModule,
    BcHeaderModule,
    BcAuthCoreModule,
    BcSnackbarModule
  ],
})
export class BcSharedComponentsModule { }
