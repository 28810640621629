import { OAuthStorage } from "angular-oauth2-oidc";

export class AppOAuthStore extends OAuthStorage {
    private readonly storage: Storage = undefined!;
  
    constructor(private readonly appName: string) {
      super();
  
      if (typeof localStorage !== 'undefined') {
        this.storage = localStorage;
      }
  
      if (typeof sessionStorage !== 'undefined') {
        this.storage = sessionStorage;
      }
    }
  
    getItem(key: string) {
      return this.storage.getItem(`${this.appName}-${key}`);
    }
  
    setItem(key : string, data: string) {
      return this.storage.setItem(`${this.appName}-${key}`, data);
    }
  
    removeItem(key: string) {
      return this.storage.removeItem(`${this.appName}-${key}`);
    }
  }