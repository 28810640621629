import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BcPhoneInputComponent } from './bc-phone-input.component';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    BcPhoneInputComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [
    BcPhoneInputComponent
  ],
})
export class BcPhoneInputModule { }