import { Component } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';


@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [    
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule
  ],
  templateUrl: './newsletter-component.html',
  styleUrl: './newsletter-component.sass'
})
export class NewsLetterComponent {
  constructor(public dialog: MatDialog) { }

  subscriptionConfirmed(){
    localStorage.setItem('nlpref', '0')
    var a = localStorage.getItem('nlpref')
    if(a == '0'){
    this.dialog.closeAll()
    var element = document.getElementById('optionalCard');
    element!.remove();
  }
  }

}
