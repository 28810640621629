<div style="text-align: center; padding: 50px">
  <div style="width: 55%; text-align: left; margin: 0 auto 20px">
    <form
      class="layout_form cr_form cr_font"
      action="https://eu.cleverreach.com/f/33488-152068/wcs/"
      method="post"
      target="_blank"
    >
      <div class="cr_body cr_page cr_font formbox">
        <div class="non_sortable" style="text-align: left">
          <h4>Bitte nutzen Sie Ihre gesch&auml;ftliche Email-Adresse.</h4>
        </div>
        <div class="non_sortable" style="text-align: left">&nbsp;</div>
        <div class="editable_content" style="text-align: left">
          <div
            id="3507484"
            class="cr_ipe_item ui-sortable"
            style="margin-bottom: 0px"
          >
            <mat-form-field style="width:100%;">
            <mat-label class="itemname" for="text3507484">Anrede</mat-label><br />
            
            <input matInput id="text3507484" style="width: 50%" type="text" name="205330" value=""
            />
          </mat-form-field>
  
        </div>

  
          <div id="3507485"class="cr_ipe_item ui-sortable"style="margin-bottom: 0px">
            <mat-form-field style="width:100%;">
            <mat-label class="itemname" for="text3507485">Vorname</mat-label>
            <input matInput id="text3507485" style="width: 100% !important" type="text" name="205332" value=""/>
          </mat-form-field>
          </div>
            


            <div id="3507486" class="cr_ipe_item ui-sortable" style="margin-bottom: 0px">
              <mat-form-field style="width:100%;">
            <mat-label class="itemname" for="text3507486">Nachname</mat-label>
            <input matInput id="text3507486" style="width: 100% !important" type="text" name="205333" value=""/>
          </mat-form-field>
          </div>
          <div
            id="3454013"
            class="cr_ipe_item ui-sortable musthave"
            style="margin-bottom: px"
          >
          <mat-form-field style="width:100%;">
            <mat-label class="itemname" for="text3454013">E-Mail*</mat-label>
            <input matInput
              id="text3454013"
              style="width: 100% !important"
              type="text"
              name="email"
              value=""
            />
          </mat-form-field>
          </div>
          <div
            id="3454015"
            class="cr_ipe_item ui-sortable submit_container"
            style="text-align: center; margin-bottom: px"
          >
            <button mat-button style="background-color: #9c0;" class="cr_button btn" type="submit" (click)="subscriptionConfirmed()">Anmelden</button>
          </div>
        </div>
        <noscript
          ><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript
        >
      </div>
    </form>
  </div>
  <div style="width: 55%; text-align: left; margin: auto">
    
    <form
      class="layout_form cr_form cr_font"
      action="https://eu.cleverreach.com/f/33488-152068/wcu/"
      method="post"
      target="_blank"
    >
      <div class="cr_body cr_page cr_font formbox">
        <div class="non_sortable" style="text-align: left">&nbsp;</div>
        <div class="editable_content" style="text-align: left">
          <div id="3454001" class="cr_ipe_item ui-sortable">
            <div class="mce_text">
              <strong>Sie m&ouml;chten sich vom Newsletter abmelden?</strong>
            </div>
            <div class="mce_text">
              Bitte geben Sie Ihre E-Mail-Adresse ein, um sich abzumelden. Sie
              k&ouml;nnen alternativ auch den Abmeldelink innerhalb des
              Newsletters verwenden.
            </div>
            <div class="mce_text">&nbsp;</div>
          </div>
          <div
            id="3454014"
            class="cr_ipe_item ui-sortable musthave"
            style="margin-bottom: px"
          >

          <mat-form-field style="width:100%;">
            <mat-label class="itemname" for="text3454014">E-Mail*</mat-label>
            <input matInput
              id="text3454014"
              style="width: 100% !important"
              type="text"
              name="email"
              value=""
            />
          </mat-form-field>
          </div>
          <div
            id="3454016"
            class="cr_ipe_item ui-sortable submit_container"
            style="text-align: center; margin-bottom: px"
          >
            <button mat-button style="background-color: #9c0;" class="cr_button btn" type="submit" (click)="subscriptionConfirmed()">Abmelden</button>
          </div>
        </div>
        <noscript
          ><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript
        >
      </div>
    </form>
  </div>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>
