/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, from, iif, Observable, of, throwError, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { exsistingRoles, RolePredictFunction } from './existing-roles';
import { RightsService } from 'bcWwsWebApi';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private isTenantLockedSubject$ = new BehaviorSubject<boolean>(false);
  public isTenantLocked$ = this.isTenantLockedSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

 /**
 * Ruft die Methode zur Abfrage von Berechtigungen über den `rightsService` auf.
 * Das zurückgegebene Observable enthält ein Array von Rollennamen, basierend auf den empfangenen Berechtigungen.
 * 
 * @type {Observable<string[]>} Ein Observable, das ein Array von Rollennamen oder ein leeres Array enthält.
 */
  private getRoles$ : Observable<string[]> = this.rightsService.apiRightsGetRightsGet().pipe(
    catchError(er => {
      this.revokeTokenAndLogout();
      return from([]);
      //return throwError(() => er);
    }),
    tap(permission =>  this.isTenantLockedSubject$.next(permission.isLocked!)),
    map( x => {
      const res = new Array<string>(); 
      if(x.isKeyuser) res.push(exsistingRoles.KeyUser)
      if(x.isKeyuserManager) res.push(exsistingRoles.KeyUserManage)
      if(x.isServiceSupportUser) res.push(exsistingRoles.ServiceSupportUser)
      if(x.isServiceSupportAdmin) res.push(exsistingRoles.ServiceSupportAdmin)
      if(x.customerNumber == 53782) res.push(exsistingRoles.CGM)
      return res;
  }));

  /**
 * Stellt ein gecachtes Observable bereit, das die Benutzerrollen in bestimmten Zeitabständen aktualisiert.
 * Das Observable wird über den `rolecache$` zugänglich gemacht und enthält das aktuelle Array von Benutzerrollen.
 * 
 * Das Observable wird wie folgt erstellt und aktualisiert:
 * 1. Ein Timer startet sofort (nach 0 ms) und wiederholt alle 60000 ms (60 Sekunden).
 * 2. Das `switchMap`-Operator verwendet das `getRoles$`-Observable, um die aktuellsten Rollen abzurufen.
 * 3. Das `shareReplay`-Operator teilt das Ergebnis, sodass es von mehreren Abonnenten geteilt werden kann,
 *    und behält eine Cache-Größe von 1, um die letzte Rolle beizubehalten.
 * 
 * @type {Observable<string[]>} Ein Observable, das das aktuelle Array von Benutzerrollen enthält.
 */
  private rolecache$ : Observable<string[]> = timer(0, 60000).pipe(
    switchMap((_) => this.getRoles$),
    shareReplay(1))



  private navigateToLoginPage() {
    // TODO: Remember current URL
    window.location.reload();
  }

  constructor(
    private rightsService: RightsService,
    private oauthService: OAuthService,
    private router: Router,
  ) {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }


      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        console.warn('... but the token is no longer valid, so we are logging out');
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(() => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(() => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe((e) => 
      {
        console.warn('Session terminated, logging out');
        console.warn(e.type);
        this.navigateToLoginPage()
      });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public navigateToVerwalten(){
    const accountPage = this.oauthService.issuer + "/account";
    window.open(accountPage, "_blank");
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() { 
    localStorage.removeItem('x-tenant');
    this.oauthService.logOut(); 
  }
  public revokeTokenAndLogout() { 
    localStorage.removeItem('x-tenant');
    this.oauthService.revokeTokenAndLogout(); 
  }
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }

  /**
 * Überprüft, ob der aktuell angemeldete Benutzer alle angeforderten Rollen besitzt.
 * 
 * @param requiredRoles Ein Array von Rollennamen, die der Benutzer besitzen muss.
 * @returns Ein Observable, das einen booleschen Wert emittiert: true, wenn der Benutzer alle Rollen hat, sonst false.
 */
  public hasRoles(requiredRoles: Array<string>, predictFunction?: RolePredictFunction): Observable<boolean> {
    const required$ = of(requiredRoles);
    const current$ = this.isAuthenticated$.pipe(mergeMap(v => v ? this.rolecache$ : from([]))) ;
    const result$ = required$.pipe(switchMap(roles =>
      iif(() => roles == null || roles.length === 0, 
        of(true), 
        combineLatest([of(roles), current$]).pipe(
            map(([elements1, elements2]) => {
            
            if(predictFunction){
              return predictFunction(elements1, elements2);
            }

            return elements1.every(element => elements2.includes(element));
        })
        ))))

    return result$;
  }
}
